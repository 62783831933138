import React, { Component } from "react";
import { setLocalData } from "../../Services/DataService";
import { Post } from "../../Utilities/CommonAPI";
import { SetToken, IsLoggedIn } from "./AuthorizeService";
import { AppData, AppPaths, ApiPaths } from "../../Services/Constants";
import Loader from "../Helpers/Loader";

export class Register extends Component {
  constructor(props) {
    super(props);
    if (IsLoggedIn()) {
      window.location.href = "/";
    }
    this.state = {
      firstName: "",
      surname: "",
      email: "",
      password: "",
      isLoading: false,
      errors: [],
    };
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSignUp = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, errors: [] });
    var body = {
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.surname,
    };
    Post(
      ApiPaths.Register,
      body,
      (response) => {
        if (response.isSuccess) {
          SetToken(response.token);
          setLocalData(AppData.Username, response.username);
          window.location.href = AppPaths.Profile;
        } else {
          this.setState({ isLoading: false, errors: response.errors });
        }
      },
      (err) => {
        this.setState({ isLoading: false, errors: err.errors });
        console.log(err);
      }
    );
  };
  render() {
    const { isLoading, firstName, surname, email, password, errors } =
      this.state;
    return (
      <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row min-vh-50">
                    <div className="col-lg-7 d-none d-lg-block bg-login-image"></div>
                    <div className="col-lg-5">
                      <div className="p-5 text-center">
                        <div className="">
                          <h1 className="h4 text-gray-900 mb-4">MyIFCM</h1>
                          <p>Impact for Christ Ministries</p>
                        </div>
                        {errors ? (
                          <ul className="text-start text-danger">
                            {errors.map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                        {isLoading ? (
                          <Loader isLoading={isLoading} />
                        ) : (
                          <form onSubmit={this.onSignUp}>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                placeholder="First Name"
                                value={firstName}
                                required
                              />
                              <label htmlFor="firstName">First Name</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                type="text"
                                className="form-control"
                                id="surname"
                                name="surname"
                                aria-describedby="surname"
                                placeholder="Surname"
                                value={surname}
                                required
                              />
                              <label htmlFor="email">Surname</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="Email Address"
                                value={email}
                                required
                              />
                              <label htmlFor="email">Email Address</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                required
                              />
                              <label htmlFor="password">Password</label>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                              <a className="small" href="/login">
                                Sign In?
                              </a>
                              <button type="submit" className="btn btn-primary">
                                Sign Up
                              </button>
                            </div>
                            <hr />
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Register;

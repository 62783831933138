import Cookies from "universal-cookie";

export function SetToken(token) {
  const cookies = new Cookies();
  cookies.set("_myifcm", { token }, { path: "/" });
}
export function GetToken() {
  const cookies = new Cookies();
  var cookie = cookies.get("_myifcm");
  return cookie ? cookie.token : null;
}
export function IsLoggedIn() {
  const cookies = new Cookies();
  var cookie = cookies.get("_myifcm");
  return cookie ? true : false;
}
export function GetUser() {
  const cookies = new Cookies();
  var cookie = cookies.get("_myifcm");
  if (cookie) {
    return cookie.token;
  }
  return { username: "" };
}

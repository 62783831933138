import React, { Component } from "react";
import NotFoundImage from "../../Assets/images/404-not-found.svg";

export class NotFound extends Component {
  render() {
    return (
      <div id="layoutError">
        <div id="layoutError_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="text-center mt-4">
                    <img
                      src={NotFoundImage}
                      alt="404-not-found"
                      className="w-100"
                    />
                    <p className="lead">The request URL was not found</p>
                    <a href="/">
                      <i className="fas fa-home"> </i>
                      <span className="my-1"> Return Home</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default NotFound;

import React from "react";

export default function Datatable({ data }) {
  const columns = data[0] && Object.keys(data[0]);

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            {data[0] &&
              columns.map((header, index) => (
                <th key={index} className="text-capitalize">
                  {header}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {columns.map((column, index) => (
                <td key={index}>
                  {column === "id" ? (
                    <a href={"/profile/" + row.id}>{row[column]}</a>
                  ) : (
                    row[column]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

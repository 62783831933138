import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getLocalData } from "../../Services/DataService";

export class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: getLocalData("_username"),
      isSidebarDisabled: false,
    };
  }
  onCloseSidebar = (e) => {
    if (window.innerWidth < 992) {
      var bar = document.getElementById("sidebarToggle");
      bar.click();
    }
  };
  render() {
    const { isSidebarDisabled, username } = this.state;
    return (
      <div id="layoutSidenav_nav">
        <nav
          className={`sb-sidenav accordion sb-sidenav-light ${
            isSidebarDisabled ? "div-disabled" : ""
          }`}
          id="sidenavAccordion"
        >
          <div className="sb-sidenav-menu">
            <div className="nav">
              <div className="sb-sidenav-menu-heading">Core</div>
              <Link className="nav-link" to="/" onClick={this.onCloseSidebar}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Dashboard
              </Link>
              <div className="sb-sidenav-menu-heading">Profile</div>
              <a
                className="nav-link collapsed"
                href="#!"
                data-bs-toggle="collapse"
                data-bs-target="#collapseLayouts"
                aria-expanded="false"
                aria-controls="collapseLayouts"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-columns"></i>
                </div>
                Account
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </a>
              <div
                className="collapse"
                id="collapseLayouts"
                aria-labelledby="headingOne"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link
                    className="nav-link"
                    to="/profile"
                    onClick={this.onCloseSidebar}
                  >
                    Personal Details
                  </Link>
                  <Link
                    className="nav-link"
                    to="/Notifications"
                    onClick={this.onCloseSidebar}
                  >
                    Notifications
                  </Link>
                  <Link
                    className="nav-link"
                    to="/security"
                    onClick={this.onCloseSidebar}
                  >
                    Security
                  </Link>
                </nav>
              </div>
              <a
                className="nav-link collapsed"
                href="#!"
                data-bs-toggle="collapse"
                data-bs-target="#collapsePages"
                aria-expanded="false"
                aria-controls="collapsePages"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-book-open"></i>
                </div>
                Administration
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </a>
              <div
                className="collapse"
                id="collapsePages"
                aria-labelledby="headingTwo"
                data-bs-parent="#sidenavAccordion"
              >
                <nav
                  className="sb-sidenav-menu-nested nav accordion"
                  id="sidenavAccordionPages"
                >
                  <a
                    className="nav-link collapsed"
                    href="#!"
                    data-bs-toggle="collapse"
                    data-bs-target="#pagesCollapseAuth"
                    aria-expanded="false"
                    aria-controls="pagesCollapseAuth"
                  >
                    Bookings
                    <div className="sb-sidenav-collapse-arrow">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div
                    className="collapse"
                    id="pagesCollapseAuth"
                    aria-labelledby="headingOne"
                    data-bs-parent="#sidenavAccordionPages"
                  >
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link
                        className="nav-link"
                        to="/bookings"
                        onClick={this.onCloseSidebar}
                      >
                        Shiloh Bookings
                      </Link>
                      <Link
                        className="nav-link"
                        to="/bookings"
                        onClick={this.onCloseSidebar}
                      >
                        Prayerline Bookings
                      </Link>

                      <Link
                        className="nav-link"
                        to="/bookings"
                        onClick={this.onCloseSidebar}
                      >
                        Via Dolorosa Bookings
                      </Link>
                    </nav>
                  </div>
                  <a
                    className="nav-link collapsed"
                    href="#!"
                    data-bs-toggle="collapse"
                    data-bs-target="#pagesCollapseError"
                    aria-expanded="false"
                    aria-controls="pagesCollapseError"
                  >
                    Reports
                    <div className="sb-sidenav-collapse-arrow">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div
                    className="collapse"
                    id="pagesCollapseError"
                    aria-labelledby="headingOne"
                    data-bs-parent="#sidenavAccordionPages"
                  >
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link
                        className="nav-link"
                        to="/create-report"
                        onClick={this.onCloseSidebar}
                      >
                        Monthly Report
                      </Link>
                      <Link
                        className="nav-link"
                        to="/search"
                        onClick={this.onCloseSidebar}
                      >
                        Family Group
                      </Link>
                      <a className="nav-link" href="500.html">
                        Testimony Report
                      </a>
                    </nav>
                  </div>
                  <a
                    className="nav-link collapsed"
                    href="#!"
                    data-bs-toggle="collapse"
                    data-bs-target="#CollapseRoleManagement"
                    aria-expanded="false"
                    aria-controls="CollapseRoleManagement"
                  >
                    User Management
                    <div className="sb-sidenav-collapse-arrow">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div
                    className="collapse"
                    id="CollapseRoleManagement"
                    aria-labelledby="headingOne"
                    data-bs-parent="#sidenavAccordionPages"
                  >
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link
                        className="nav-link"
                        to="/user-roles"
                        onClick={this.onCloseSidebar}
                      >
                        User Roles
                      </Link>
                    </nav>
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link
                        className="nav-link"
                        to="/groups"
                        onClick={this.onCloseSidebar}
                      >
                        Groups
                      </Link>
                    </nav>
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link
                        className="nav-link"
                        to="/members"
                        onClick={this.onCloseSidebar}
                      >
                        Members
                      </Link>
                    </nav>
                  </div>
                </nav>
              </div>
              <Link className="nav-link" to="/feedback">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Feedback
              </Link>
            </div>
          </div>
          <div className="sb-sidenav-footer">
            <div className="small">Logged in as:</div>
            {username}
          </div>
        </nav>
      </div>
    );
  }
}

export default Sidebar;

import React, { useState, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import { getData, removeLocalData } from "../../Services/DataService";
import { ApiPaths, AppData } from "../../Services/Constants";
import profilePicture from "../../Assets/images/profile-1.png";
import Address from "./Address";
import NextOfKin from "./NextOfKin";
import PersonalDetails from "./PersonalDetails";
import ProfessionAndQualifications from "./ProfessionAndQualifications";
import axios from "axios";
import { GetToken } from "../../Components/Authentication/AuthorizeService";

export default function Profile() {
  let params = useParams();
  const [model, setModel] = useState({});
  const [isPersonalDetailsVisible, setIsPersonalDetailsVisible] = useState(
    params.id ? false : true
  );
  const [isAddressDetailsVisibile, setIsAddressDetailsVisibile] =
    useState(false);
  const [isNextOfKinDetailsVisibile, setIsNextOfKinDetailsVisibile] =
    useState(false);
  const [isProfessionalDetailsVisibile, setIsProfessionalDetailsVisibile] =
    useState(false);
  const [isMember, setIsMember] = useState(params.id);
  const inputRef = React.useRef();
  const [profile, setProfile] = useState(profilePicture);
  const UserContext = createContext();

  useEffect(() => {
    if (params && params.id) {
      setIsMember(params.id);
    } else {
      getData(
        ApiPaths.PersonalDetails,
        AppData.PeronsalDetails,
        (data) => {
          // setProfile(data.profilePicture);
          setModel(data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, []);

  function changeHandler(e) {
    setModel({ [e.target.name]: e.target.value });
  }

  async function uploadProfilePicture(e) {
    e.preventDefault();
    var image = inputRef.current.files[0];
    if (image) {
      var formdata = new FormData();
      formdata.append("FileName", image.name);
      formdata.append("FormFile", image, image.name);

      try {
        var cookie = GetToken();
        if (!cookie) {
          return;
        }
        await axios
          .post(
            ApiPaths.BaseUrl + "/PersonalDetails/update/profile-picture",
            formdata,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.token,
              },
            }
          )
          .then((response) => {
            removeLocalData(AppData.PeronsalDetails);
            setProfile(response.data.path);
            inputRef.current.value = "";
          });
      } catch (error) {
        console.log(error);
      }

      // Post(
      //   "/PersonalDetails/update/profile-picture",
      //   { formFile: profilePicture },
      //   (response) => {
      //     // this.setState({ isLoading: false });
      //     // this.closeSection(e);
      //     removeLocalData(AppData.PeronsalDetails);
      //     toast.success("Updated successfully");
      //   },
      //   (err) => {
      //     // this.setState({ isLoading: false });
      //     toast.error("Update unsuccessful!");
      //     console.log(err);
      //   },
      //   true
      // );
    }
  }

  function openSection(e) {
    var currentAction = e.target.getAttribute("data-section");
    this.closeSection();
    switch (currentAction) {
      case "account-details":
        setIsPersonalDetailsVisible(true);
        break;
      case "address-details":
        setIsAddressDetailsVisibile(true);
        break;
      case "next-of-kin-details":
        setIsNextOfKinDetailsVisibile(true);
        break;
      case "profession-and-qualifications-details":
        setIsProfessionalDetailsVisibile(true);
        break;
      default:
        break;
    }
  }

  function closeSection(e) {
    setIsPersonalDetailsVisible(false);
    setIsAddressDetailsVisibile(false);
    setIsNextOfKinDetailsVisibile(false);
    setIsProfessionalDetailsVisibile(false);
  }

  return (
    <div className="container-fluid px-4">
      <h1 className="mt-4">Profile</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active">Account</li>
        <li className="breadcrumb-item active">Personal Details</li>
      </ol>
      <div className="row">
        <div className="col-xl-3">
          <div className="card shadow-lg mb-4 mb-xl-0">
            <div className="card-header">Profile Picture</div>
            <div className="card-body text-center">
              <img
                className="img-account-profile rounded-circle mb-2"
                src={profile}
                alt="member-profile"
              />
              <div className="small font-italic text-muted mb-4">
                JPG or PNG no larger than 5 MB
              </div>
              <input
                ref={inputRef}
                type="file"
                className="btn btn-sm"
                id="profilePicture"
                name="profilePicture"
                accept="image/*"
                onChange={changeHandler}
              />
            </div>
            <button
              className="btn btn-primary btn-sm"
              onClick={uploadProfilePicture}
            >
              Save
            </button>
          </div>
        </div>
        <div className="col-xl-9">
          <PersonalDetails
            isMember={isMember}
            model={model}
            isVisible={isPersonalDetailsVisible}
            openSection={openSection}
            closeSection={closeSection}
            changeHandler={changeHandler}
          />
          <Address
            model={model}
            isVisible={isAddressDetailsVisibile}
            openSection={openSection}
            closeSection={closeSection}
          />
          <ProfessionAndQualifications
            model={model}
            isVisible={isProfessionalDetailsVisibile}
            openSection={openSection}
            closeSection={closeSection}
          />
          <NextOfKin
            model={model}
            isVisible={isNextOfKinDetailsVisibile}
            openSection={openSection}
            closeSection={closeSection}
          />
        </div>
      </div>
    </div>
  );
}

export const AppData = {
  Username: "_username",
  PeronsalDetails: "_personalDetails",
  Address: "_address",
};

export const AppPaths = {
  Profile: "/profile",
};

export const ApiPaths = {
  BaseUrl: "https://dev.impactforchristsa.com",
  // BaseUrl: "https://localhost:7446",
  Login: "/login",
  Logout: "/logout",
  Register: "/account/register",
  ResendEmailConfirmation: "/ResendEmailConfirmation",
  ResetPassword: "/ResetPassword",
  FamilyGroup: "/FamilyGroup",
  Members: "/members",
  MembersImport: "/members/import",
  PersonalDetails: "/PersonalDetails",
  PersonalDetailsUpdate: "/PersonalDetails/Update",
  PersonalDetailsUpdateAddress: "/PersonalDetails/Update/address",
  PersonalDetailsUpdateNextOfKin: "/PersonalDetails/Update/nextofkin",
  PersonalDetailsUpdateQualifications: "/PersonalDetails/Update/qualifications",
  PersonalDetailsUpdateProfilePicture: "/PersonalDetails/profile-picture",
  roles: "/api/roles",
  rolesUpdate: "/api/roles/update",
};

import React from "react";
import image from "../../Assets/images/futuristic_hud.svg";

export default function Bookings() {
  return (
    <div className="container-fluid px-4">
      <h1 className="mt-4">Administration</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active">Bookings</li>
        <li className="breadcrumb-item active">Events</li>
      </ol>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card shadow-lg mb-4 mb-xl-0">
            <div className="card-header">Bookings - Feature In Development</div>
            <div className="card-body text-center">
              <img className="mh-icon-25" src={image} alt="coming-soon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { GetCountries } from "../../Utilities/DataLists";
import { Post } from "../../Utilities/CommonAPI";
import { ApiPaths, AppData } from "../../Services/Constants";
import { getData, removeLocalData } from "../../Services/DataService";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";

export class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      houseOrUnitNumber: "",
      complexName: "",
      estateName: "",
      streetAddress: "",
      suburb: "",
      country: "",
      city: "",
      province: "",
      postalCode: "",
      isVisible: this.props.isVisible,
      countries: GetCountries(),
      isLoading: false,
    };
  }
  componentDidMount() {
    getData(
      ApiPaths.PersonalDetails,
      AppData.PeronsalDetails,
      (data) => {
        if (data.address) {
          this.setModel(data.address);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setModel = (model) => {
    this.setState({
      houseOrUnitNumber: model.houseNumber ? model.houseNumber : "",
      complexName: model.complexName ? model.complexName : "",
      estateName: model.estateName ? model.estateName : "",
      streetAddress: model.streetAddress ? model.streetAddress : "",
      suburb: model.suburb ? model.suburb : "",
      country: model.country ? model.country : "",
      city: model.city ? model.city : "",
      province: model.province ? model.province : "",
      postalCode: model.postalCode ? model.postalCode : "",
    });
  };
  openSection = (e) => {
    this.props.openSection(e);
  };
  closeSection = (e) => {
    this.props.closeSection(e);
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onUpdate = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var body = {
      HouseNumber: this.state.houseOrUnitNumber,
      complexName: this.state.complexName,
      estateName: this.state.estateName,
      streetAddress: this.state.streetAddress,
      suburb: this.state.suburb,
      country: this.state.country,
      city: this.state.city,
      province: this.state.province,
      postalCode: this.state.postalCode,
    };
    Post(
      "/PersonalDetails/Update/Address",
      body,
      (response) => {
        this.setState({ isLoading: false });
        this.closeSection(e);
        removeLocalData(AppData.PeronsalDetails);
        toast.success("Updated successfully");
      },
      (err) => {
        this.setState({ isLoading: false });
        toast.error("Update unsuccessful!");
        console.log(err);
      },
      true
    );
  };
  render() {
    const isVisible = this.props.isVisible;
    const {
      houseOrUnitNumber,
      complexName,
      estateName,
      streetAddress,
      suburb,
      country,
      city,
      province,
      postalCode,
      countries,
      isLoading,
    } = this.state;
    return (
      <div className="card shadow-lg mb-4">
        <div className="card-header py-2">
          <div className="row">
            <div className="col-sm-8">Address Details</div>
            <div className="col-sm-4">
              {!isVisible ? (
                <button
                  onClick={this.openSection}
                  type="button"
                  className="btn btn-primary btn-sm float-end"
                  data-section="address-details"
                >
                  Edit
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {isVisible ? (
          <div className="card-body">
            {isLoading ? (
              <Loader isLoading={isLoading} />
            ) : (
              <div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="houseOrUnitNumber"
                        name="houseOrUnitNumber"
                        type="text"
                        placeholder="House/Unit Number"
                        value={houseOrUnitNumber}
                      />
                      <label className="small mb-1" htmlFor="houseOrUnitNumber">
                        House/Unit Number
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="estateName"
                        name="estateName"
                        type="text"
                        placeholder="Estate Name"
                        value={estateName}
                      />
                      <label className="small mb-1" htmlFor="estateName">
                        Estate Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="complexName"
                        name="complexName"
                        type="text"
                        placeholder="Complex Name"
                        value={complexName}
                      />
                      <label className="small mb-1" htmlFor="complexName">
                        Complex Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="streetAddress"
                        name="streetAddress"
                        type="text"
                        placeholder="Street Name"
                        value={streetAddress}
                      />
                      <label className="small mb-1" htmlFor="streetAddress">
                        Street Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="suburb"
                        name="suburb"
                        type="text"
                        placeholder="suburb"
                        value={suburb}
                      />
                      <label className="small mb-1" htmlFor="suburb">
                        Suburb
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="city"
                        name="city"
                        type="text"
                        placeholder="City"
                        value={city}
                      />
                      <label className="small mb-1" htmlFor="city">
                        City
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="province"
                        name="province"
                        placeholder="Province/State"
                        value={province}
                      />
                      <label className="small mb-1" htmlFor="province">
                        Province/State
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="country"
                        name="country"
                        type="text"
                        value={country}
                      >
                        <option value="">-- Select --</option>
                        {countries.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </select>
                      <label className="small mb-1" htmlFor="country">
                        Country
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-floating">
                      <input
                        required
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="postalCode"
                        name="postalCode"
                        type="number"
                        placeholder="Postal Code"
                        value={postalCode}
                      />
                      <label className="small mb-1" htmlFor="postalCode">
                        Postal Code
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-secondary mx-2"
                    onClick={this.closeSection}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={this.onUpdate}
                    className="btn btn-primary mx-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="card-body">
            <div className="row gx-3">
              <div className="col-md-6">
                <label className="small mb-3">
                  House/Unit Number: <strong>{houseOrUnitNumber}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Estate Name: <strong>{estateName}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Complex Name: <strong>{complexName}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Street Address: <strong>{streetAddress}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Suburb: <strong>{suburb}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  City: <strong>{city}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Province: <strong>{province}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Country: <strong>{country}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Postal Code: <strong>{postalCode}</strong>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Address;

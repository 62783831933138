import React, { Component } from "react";
import { AppData } from "../../Services/Constants";
import { setLocalData } from "../../Services/DataService";
import { Post } from "../../Utilities/CommonAPI";
import Loader from "../Helpers/Loader";
import { SetToken, IsLoggedIn } from "./AuthorizeService";

export class Login extends Component {
  constructor(props) {
    super(props);
    if (IsLoggedIn()) {
      window.location.href = "/";
      return;
    }
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      isLoading: false,
      errors: [],
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onSignIn = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    Post(
      "/login",
      {
        username: this.state.email,
        password: this.state.password,
      },
      (data) => {
        if (data && data.isSuccess) {
          SetToken(data);
          setLocalData(AppData.Username, data.username);
          window.location.href = "/";
        } else {
          this.setState({ isLoading: false, errors: data.errors });
        }
      },
      (error) => {
        console.log(error);
        this.setState({ isLoading: false, errors: error.errors });
      }
    );
  };

  render() {
    const { email, password, isLoading, errors } = this.state;
    return (
      <section className="pt-xxl-5">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">MyIFCM</h1>
                          <p>Impact for Christ Ministries</p>
                        </div>
                        {errors ? (
                          <ul className="text-start text-danger">
                            {errors.map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                        {isLoading ? (
                          <Loader isLoading={isLoading} />
                        ) : (
                          <form className="" onSubmit={this.onSignIn}>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                required
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                value={email}
                              />
                              <label htmlFor="email">Email Address</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                required
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="Password"
                                value={password}
                              />
                              <label htmlFor="password">Password</label>
                            </div>
                            {/* <div className="form-check mb-3">
                              <input
                                onChange={this.toggleChange}
                                type="checkbox"
                                className="form-check-input"
                                name="rememberMe"
                                defaultChecked={rememberMe}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rememberMe"
                              >
                                Remember Me
                              </label>
                            </div> */}
                            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                              <a className="small" href="/forgot-password">
                                Forgot Password?
                              </a>
                              <button
                                type="submit"
                                className="btn btn-primary"
                                href="/"
                              >
                                Login
                              </button>
                            </div>
                            <hr />
                          </form>
                        )}
                        <div className="text-center">
                          <a className="small" href="/register">
                            Create an Account!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;

import React, { Component } from "react";
import {
  GetNationalities,
  Titles,
  MaritalStatuses,
} from "../../Utilities/DataLists";
import { Post } from "../../Utilities/CommonAPI";
import {
  getData,
  getLocalData,
  removeLocalData,
} from "../../Services/DataService";
import { ApiPaths, AppData } from "../../Services/Constants";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";

export class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      gender: "",
      maritalStatus: "",
      nationality: "",
      cellphone: "",
      email: getLocalData("_username"),
      isVisible: this.props.isVisible,
      nationalities: GetNationalities(),
      titles: Titles(),
      maritalStatuses: MaritalStatuses(),
      isLoading: false,
    };
  }
  componentDidMount() {
    if (this.props.isMember) {
      fetch("/Members/" + this.props.isMember)
        .then((response) => response.json())
        .then((json) => {
          this.setModel(json);
        });
    } else {
      getData(
        ApiPaths.PersonalDetails,
        AppData.PeronsalDetails,
        (data) => {
          this.setModel(data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  setModel = (model) => {
    this.setState({
      title: model.title ? model.title : "",
      firstName: model.firstName ? model.firstName : "",
      lastName: model.lastName ? model.lastName : "",
      dateOfBirth: model.dateOfBirth ? model.dateOfBirth.substr(0, 10) : "",
      gender: model.gender ? model.gender : "",
      maritalStatus: model.maritalStatus ? model.maritalStatus : "",
      nationality: model.nationality ? model.nationality : "",
      cellphone: model.cellphoneNumber ? model.cellphoneNumber : "",
    });
  };
  openSection = (e) => {
    this.props.openSection(e);
  };
  closeSection = (e) => {
    this.props.closeSection(e);
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onUpdate = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var body = {
      title: this.state.title,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dateOfBirth: this.state.dateOfBirth,
      gender: this.state.gender,
      maritalStatus: this.state.maritalStatus,
      nationality: this.state.nationality,
      cellphoneNumber: this.state.cellphone,
    };
    Post(
      ApiPaths.PersonalDetailsUpdate,
      body,
      (response) => {
        this.setState({ isLoading: false });
        removeLocalData(AppData.PeronsalDetails);
        this.closeSection(e);
        toast.success("Updated successfully");
      },
      (err) => {
        this.setState({ isLoading: false });
        toast.error("Update unsuccessful!");
        console.log(err);
      },
      true
    );
  };
  render() {
    const isVisible = this.props.isVisible;
    const {
      title,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      maritalStatus,
      nationality,
      cellphone,
      email,
      nationalities,
      titles,
      maritalStatuses,
      isLoading,
    } = this.state;
    return (
      <div className="card shadow-lg mb-4">
        <div className="card-header py-2">
          <div className="row">
            <div className="col-sm-8">Personal Details</div>
            <div className="col-sm-4">
              {!isVisible ? (
                <button
                  onClick={this.openSection}
                  type="button"
                  className="btn btn-primary btn-sm float-end"
                  data-section="account-details"
                >
                  Edit
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {isVisible ? (
          <div className="card-body">
            {isLoading ? (
              <Loader isLoading={isLoading} />
            ) : (
              <form onSubmit={this.onUpdate}>
                <div className="row gx-3">
                  <div className="col-md-2 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        value={title}
                        id="title"
                        name="title"
                      >
                        <option value="">-- Select --</option>
                        {titles.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </select>
                      <label className="small mb-1" htmlFor="title">
                        Title
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                      />
                      <label className="small mb-1" htmlFor="firstName">
                        First Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                      />
                      <label className="small mb-1" htmlFor="lastName">
                        Last name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row gx-3">
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        value={dateOfBirth}
                      />
                      <label className="small mb-1" htmlFor="dateOfBirth">
                        Date of Birth
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="gender"
                        name="gender"
                        value={gender}
                      >
                        <option value="">-- Select --</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <label className="small mb-1" htmlFor="gender">
                        Gender
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="maritalStatus"
                        name="maritalStatus"
                        value={maritalStatus}
                      >
                        <option value="">-- Select --</option>
                        {maritalStatuses.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </select>
                      <label className="small mb-1" htmlFor="maritalStatus">
                        Marital Status
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="nationality"
                        name="nationality"
                        type="text"
                        value={nationality}
                      >
                        <option value="">-- Select --</option>
                        {nationalities.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </select>
                      <label className="small mb-1" htmlFor="nationality">
                        Nationality
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="cellphone"
                        name="cellphone"
                        type="number"
                        placeholder="Cellphone Number"
                        value={cellphone}
                        maxLength={15}
                      />
                      <label className="small mb-1" htmlFor="cellphone">
                        Cellphone Number
                      </label>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        readOnly
                      />
                      <label className="small mb-1" htmlFor="email">
                        Email
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-secondary mx-2"
                    onClick={this.closeSection}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary mx-2">
                    Update
                  </button>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div className="card-body">
            <div className="row gx-3">
              <div className="col-md-6">
                <label className="small mb-3">
                  Title: <strong>{title}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  First name: <strong>{firstName}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Last name: <strong>{lastName}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Date of birth: <strong>{dateOfBirth}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Gender: <strong>{gender}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Marital Status: <strong>{maritalStatus}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Nationality: <strong>{nationality}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-1">
                  Cellphone: <strong>{cellphone}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Email: <strong>{email}</strong>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PersonalDetails;

import React, { useState, useEffect } from "react";
import Loader from "../Helpers/Loader";
import Datatable from "../Reports/Datatable";
import Search from "../Reports/Search";
import { ApiPaths } from "../../Services/Constants";

export default function Members() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState([
    "firstName",
    "lastName",
    "cellphone",
  ]);

  useEffect(() => {
    fetch(ApiPaths.BaseUrl + "/Members")
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setIsLoading(false);
      });
  }, []);

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }
  return (
    <>
      <header className="border-bottom bg-white mb-4">
        <div className="container-fluid px-4">
          <div className="page-header-content">
            <div className="row align-items-center justify-content-between pt-3">
              <div className="col-auto mb-3">
                <h4 className="">Members</h4>
              </div>
              <div className="col-12 col-xl-auto mb-3">
                <a className="btn btn-sm btn-primary me-3" href="/groups">
                  Manage Groups
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="">
        <Search />
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  User Role
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {isLoading ? (
                <Loader isLoading={isLoading} />
              ) : (
                <div>
                  <div className="modal-body">
                    <div className="">
                      <label className="mb-3" htmlFor="roleName">
                        Role Name
                      </label>
                      <input
                        id="roleName"
                        name="roleName"
                        type="text"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Add Role
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

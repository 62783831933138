import React, { Component } from "react";

export class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: this.props.isLoading,
    };
  }
  render() {
    const isLoading = this.props.isLoading;
    return (
      <div>
        {isLoading ? (
          <div>
            <div className="d-flex justify-content-center vh-20">
              <div
                className="spinner-border text-dark position-relative top-50"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Loader;

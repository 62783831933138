export function MaritalStatuses() {
  return [
    { key: "Single", value: "Single" },
    { key: "Married", value: "Married" },
    { key: "Divorced", value: "Divorced" },
    { key: "Its Complicated", value: "Its Complicated" },
  ];
}
export function Relationships() {
  return [
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Spouse", value: "Spouse" },
    { key: "Grandparent", value: "Grandparent" },
    { key: "Uncle", value: "Uncle" },
    { key: "Aunt", value: "Aunt" },
    { key: "Gaurdian", value: "Gaurdian" },
    { key: "Brother", value: "Brother" },
    { key: "Sister", value: "Sister" },
    { key: "Friend", value: "Fiend" },
  ];
}
export function Titles() {
  return [
    { key: "Mr", value: "Mr" },
    { key: "Mrs", value: "Mrs" },
    { key: "Miss", value: "Miss" },
    { key: "Dr", value: "Dr" },
    { key: "Advocate", value: "Advocate" },
    { key: "Professor", value: "Professor" },
  ];
}
export function GetCountries() {
  return [
    { key: "Afghanistan", value: "Afghanistan" },
    { key: "Åland Islands", value: "Åland Islands" },
    { key: "Albania", value: "Albania" },
    { key: "Algeria", value: "Algeria" },
    { key: "American Samoa", value: "American Samoa" },
    { key: "Andorra", value: "Andorra" },
    { key: "Angola", value: "Angola" },
    { key: "Anguilla", value: "Anguilla" },
    { key: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { key: "Argentina", value: "Argentina" },
    { key: "Armenia", value: "Armenia" },
    { key: "Aruba", value: "Aruba" },
    { key: "Australia", value: "Australia" },
    { key: "Austria", value: "Austria" },
    { key: "Azerbaijan", value: "Azerbaijan" },
    { key: "Bahamas", value: "Bahamas" },
    { key: "Bahrain", value: "Bahrain" },
    { key: "Bangladesh", value: "Bangladesh" },
    { key: "Barbados", value: "Barbados" },
    { key: "Belarus", value: "Belarus" },
    { key: "Belgium", value: "Belgium" },
    { key: "Belize", value: "Belize" },
    { key: "Benin", value: "Benin" },
    { key: "Bermuda", value: "Bermuda" },
    { key: "Bhutan", value: "Bhutan" },
    { key: "Bolivia", value: "Bolivia" },
    {
      key: "Bonaire, Sint Eustatius and Saba",
      value: "Bonaire, Sint Eustatius and Saba",
    },
    { key: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { key: "Botswana", value: "Botswana" },
    { key: "Brazil", value: "Brazil" },
    {
      key: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
    },
    { key: "British Virgin Islands", value: "British Virgin Islands" },
    { key: "Brunei", value: "Brunei" },
    { key: "Bulgaria", value: "Bulgaria" },
    { key: "Burkina Faso", value: "Burkina Faso" },
    { key: "Burundi", value: "Burundi" },
    { key: "Cabo Verde", value: "Cabo Verde" },
    { key: "Cambodia", value: "Cambodia" },
    { key: "Cameroon", value: "Cameroon" },
    { key: "Canada", value: "Canada" },
    { key: "Caribbean", value: "Caribbean" },
    { key: "Cayman Islands", value: "Cayman Islands" },
    { key: "Central African Republic", value: "Central African Republic" },
    { key: "Chad", value: "Chad" },
    { key: "Chile", value: "Chile" },
    { key: "China", value: "China" },
    { key: "Christmas Island", value: "Christmas Island" },
    { key: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
    { key: "Colombia", value: "Colombia" },
    { key: "Comoros", value: "Comoros" },
    { key: "Congo", value: "Congo" },
    { key: "Congo (DRC)", value: "Congo (DRC)" },
    { key: "Cook Islands", value: "Cook Islands" },
    { key: "Costa Rica", value: "Costa Rica" },
    { key: "Côte d’Ivoire", value: "Côte d’Ivoire" },
    { key: "Croatia", value: "Croatia" },
    { key: "Cuba", value: "Cuba" },
    { key: "Curaçao", value: "Curaçao" },
    { key: "Cyprus", value: "Cyprus" },
    { key: "Czechia", value: "Czechia" },
    { key: "Denmark", value: "Denmark" },
    { key: "Djibouti", value: "Djibouti" },
    { key: "Dominica", value: "Dominica" },
    { key: "Dominican Republic", value: "Dominican Republic" },
    { key: "Ecuador", value: "Ecuador" },
    { key: "Egypt", value: "Egypt" },
    { key: "El Salvador", value: "El Salvador" },
    { key: "Equatorial Guinea", value: "Equatorial Guinea" },
    { key: "Eritrea", value: "Eritrea" },
    { key: "Estonia", value: "Estonia" },
    { key: "Ethiopia", value: "Ethiopia" },
    { key: "Europe", value: "Europe" },
    { key: "Falkland Islands", value: "Falkland Islands" },
    { key: "Faroe Islands", value: "Faroe Islands" },
    { key: "Fiji", value: "Fiji" },
    { key: "Finland", value: "Finland" },
    { key: "France", value: "France" },
    { key: "French Guiana", value: "French Guiana" },
    { key: "French Polynesia", value: "French Polynesia" },
    { key: "Gabon", value: "Gabon" },
    { key: "Gambia", value: "Gambia" },
    { key: "Georgia", value: "Georgia" },
    { key: "Germany", value: "Germany" },
    { key: "Ghana", value: "Ghana" },
    { key: "Gibraltar", value: "Gibraltar" },
    { key: "Greece", value: "Greece" },
    { key: "Greenland", value: "Greenland" },
    { key: "Grenada", value: "Grenada" },
    { key: "Guadeloupe", value: "Guadeloupe" },
    { key: "Guam", value: "Guam" },
    { key: "Guatemala", value: "Guatemala" },
    { key: "Guernsey", value: "Guernsey" },
    { key: "Guinea", value: "Guinea" },
    { key: "Guinea-Bissau", value: "Guinea-Bissau" },
    { key: "Guyana", value: "Guyana" },
    { key: "Haiti", value: "Haiti" },
    { key: "Honduras", value: "Honduras" },
    { key: "Hong Kong SAR", value: "Hong Kong SAR" },
    { key: "Hungary", value: "Hungary" },
    { key: "Iceland", value: "Iceland" },
    { key: "India", value: "India" },
    { key: "Indonesia", value: "Indonesia" },
    { key: "Iran", value: "Iran" },
    { key: "Iraq", value: "Iraq" },
    { key: "Ireland", value: "Ireland" },
    { key: "Isle of Man", value: "Isle of Man" },
    { key: "Israel", value: "Israel" },
    { key: "Italy", value: "Italy" },
    { key: "Jamaica", value: "Jamaica" },
    { key: "Japan", value: "Japan" },
    { key: "Jersey", value: "Jersey" },
    { key: "Jordan", value: "Jordan" },
    { key: "Kazakhstan", value: "Kazakhstan" },
    { key: "Kenya", value: "Kenya" },
    { key: "Kiribati", value: "Kiribati" },
    { key: "Korea", value: "Korea" },
    { key: "Kosovo", value: "Kosovo" },
    { key: "Kuwait", value: "Kuwait" },
    { key: "Kyrgyzstan", value: "Kyrgyzstan" },
    { key: "Laos", value: "Laos" },
    { key: "Latin America", value: "Latin America" },
    { key: "Latvia", value: "Latvia" },
    { key: "Lebanon", value: "Lebanon" },
    { key: "Lesotho", value: "Lesotho" },
    { key: "Liberia", value: "Liberia" },
    { key: "Libya", value: "Libya" },
    { key: "Liechtenstein", value: "Liechtenstein" },
    { key: "Lithuania", value: "Lithuania" },
    { key: "Luxembourg", value: "Luxembourg" },
    { key: "Macao SAR", value: "Macao SAR" },
    { key: "Macedonia, FYRO", value: "Macedonia, FYRO" },
    { key: "Madagascar", value: "Madagascar" },
    { key: "Malawi", value: "Malawi" },
    { key: "Malaysia", value: "Malaysia" },
    { key: "Maldives", value: "Maldives" },
    { key: "Mali", value: "Mali" },
    { key: "Malta", value: "Malta" },
    { key: "Marshall Islands", value: "Marshall Islands" },
    { key: "Martinique", value: "Martinique" },
    { key: "Mauritania", value: "Mauritania" },
    { key: "Mauritius", value: "Mauritius" },
    { key: "Mayotte", value: "Mayotte" },
    { key: "Mexico", value: "Mexico" },
    { key: "Micronesia", value: "Micronesia" },
    { key: "Moldova", value: "Moldova" },
    { key: "Monaco", value: "Monaco" },
    { key: "Mongolia", value: "Mongolia" },
    { key: "Montenegro", value: "Montenegro" },
    { key: "Montserrat", value: "Montserrat" },
    { key: "Morocco", value: "Morocco" },
    { key: "Mozambique", value: "Mozambique" },
    { key: "Myanmar", value: "Myanmar" },
    { key: "Namibia", value: "Namibia" },
    { key: "Nauru", value: "Nauru" },
    { key: "Nepal", value: "Nepal" },
    { key: "Netherlands", value: "Netherlands" },
    { key: "New Caledonia", value: "New Caledonia" },
    { key: "New Zealand", value: "New Zealand" },
    { key: "Nicaragua", value: "Nicaragua" },
    { key: "Niger", value: "Niger" },
    { key: "Nigeria", value: "Nigeria" },
    { key: "Niue", value: "Niue" },
    { key: "Norfolk Island", value: "Norfolk Island" },
    { key: "North Korea", value: "North Korea" },
    { key: "Northern Mariana Islands", value: "Northern Mariana Islands" },
    { key: "Norway", value: "Norway" },
    { key: "Oman", value: "Oman" },
    { key: "Pakistan", value: "Pakistan" },
    { key: "Palau", value: "Palau" },
    { key: "Palestinian Authority", value: "Palestinian Authority" },
    { key: "Panama", value: "Panama" },
    { key: "Papua New Guinea", value: "Papua New Guinea" },
    { key: "Paraguay", value: "Paraguay" },
    { key: "Peru", value: "Peru" },
    { key: "Philippines", value: "Philippines" },
    { key: "Pitcairn Islands", value: "Pitcairn Islands" },
    { key: "Poland", value: "Poland" },
    { key: "Portugal", value: "Portugal" },
    { key: "Puerto Rico", value: "Puerto Rico" },
    { key: "Qatar", value: "Qatar" },
    { key: "Réunion", value: "Réunion" },
    { key: "Romania", value: "Romania" },
    { key: "Russia", value: "Russia" },
    { key: "Rwanda", value: "Rwanda" },
    { key: "Saint Barthélemy", value: "Saint Barthélemy" },
    { key: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { key: "Saint Lucia", value: "Saint Lucia" },
    { key: "Saint Martin", value: "Saint Martin" },
    { key: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
    {
      key: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    { key: "Samoa", value: "Samoa" },
    { key: "San Marino", value: "San Marino" },
    { key: "São Tomé and Príncipe", value: "São Tomé and Príncipe" },
    { key: "Saudi Arabia", value: "Saudi Arabia" },
    { key: "Senegal", value: "Senegal" },
    { key: "Serbia", value: "Serbia" },
    { key: "Seychelles", value: "Seychelles" },
    { key: "Sierra Leone", value: "Sierra Leone" },
    { key: "Singapore", value: "Singapore" },
    { key: "Sint Maarten", value: "Sint Maarten" },
    { key: "Slovakia", value: "Slovakia" },
    { key: "Slovenia", value: "Slovenia" },
    { key: "Solomon Islands", value: "Solomon Islands" },
    { key: "Somalia", value: "Somalia" },
    { key: "South Africa", value: "South Africa" },
    { key: "South Sudan", value: "South Sudan" },
    { key: "Spain", value: "Spain" },
    { key: "Sri Lanka", value: "Sri Lanka" },
    {
      key: "St Helena, Ascension, Tristan da Cunha",
      value: "St Helena, Ascension, Tristan da Cunha",
    },
    { key: "Sudan", value: "Sudan" },
    { key: "Suriname", value: "Suriname" },
    { key: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
    { key: "Swaziland", value: "Swaziland" },
    { key: "Sweden", value: "Sweden" },
    { key: "Switzerland", value: "Switzerland" },
    { key: "Syria", value: "Syria" },
    { key: "Taiwan", value: "Taiwan" },
    { key: "Tajikistan", value: "Tajikistan" },
    { key: "Tanzania", value: "Tanzania" },
    { key: "Thailand", value: "Thailand" },
    { key: "Timor-Leste", value: "Timor-Leste" },
    { key: "Togo", value: "Togo" },
    { key: "Tokelau", value: "Tokelau" },
    { key: "Tonga", value: "Tonga" },
    { key: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { key: "Tunisia", value: "Tunisia" },
    { key: "Turkey", value: "Turkey" },
    { key: "Turkmenistan", value: "Turkmenistan" },
    { key: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
    { key: "Tuvalu", value: "Tuvalu" },
    { key: "U.S. Outlying Islands", value: "U.S. Outlying Islands" },
    { key: "U.S. Virgin Islands", value: "U.S. Virgin Islands" },
    { key: "Uganda", value: "Uganda" },
    { key: "Ukraine", value: "Ukraine" },
    { key: "United Arab Emirates", value: "United Arab Emirates" },
    { key: "United Kingdom", value: "United Kingdom" },
    { key: "United States", value: "United States" },
    { key: "Uruguay", value: "Uruguay" },
    { key: "Uzbekistan", value: "Uzbekistan" },
    { key: "Vanuatu", value: "Vanuatu" },
    { key: "Vatican City", value: "Vatican City" },
    { key: "Venezuela", value: "Venezuela" },
    { key: "Vietnam", value: "Vietnam" },
    { key: "Wallis and Futuna", value: "Wallis and Futuna" },
    { key: "World", value: "World" },
    { key: "Yemen", value: "Yemen" },
    { key: "Zambia", value: "Zambia" },
    { key: "Zimbabwe", value: "Zimbabwe" },
  ];
}
export function GetNationalities() {
  return [
    { key: "Afghan", value: "Afghan" },
    { key: "Albanian", value: "Albanian" },
    { key: "Algerian", value: "Algerian" },
    { key: "American", value: "American" },
    { key: "Andorran", value: "Andorran" },
    { key: "Angolan", value: "Angolan" },
    { key: "Antiguans", value: "Antiguans" },
    { key: "Argentinean", value: "Argentinean" },
    { key: "Armenian", value: "Armenian" },
    { key: "Australian", value: "Australian" },
    { key: "Austrian", value: "Austrian" },
    { key: "Azerbaijani", value: "Azerbaijani" },
    { key: "Bahamian", value: "Bahamian" },
    { key: "Bahraini", value: "Bahraini" },
    { key: "Bangladeshi", value: "Bangladeshi" },
    { key: "Barbadian", value: "Barbadian" },
    { key: "Barbudans", value: "Barbudans" },
    { key: "Batswana", value: "Batswana" },
    { key: "Belarusian", value: "Belarusian" },
    { key: "Belgian", value: "Belgian" },
    { key: "Belizean", value: "Belizean" },
    { key: "Beninese", value: "Beninese" },
    { key: "Bhutanese", value: "Bhutanese" },
    { key: "Bolivian", value: "Bolivian" },
    { key: "Bosnian", value: "Bosnian" },
    { key: "Brazilian", value: "Brazilian" },
    { key: "British", value: "British" },
    { key: "Bruneian", value: "Bruneian" },
    { key: "Bulgarian", value: "Bulgarian" },
    { key: "Burkinabe", value: "Burkinabe" },
    { key: "Burmese", value: "Burmese" },
    { key: "Burundian", value: "Burundian" },
    { key: "Cambodian", value: "Cambodian" },
    { key: "Cameroonian", value: "Cameroonian" },
    { key: "Canadian", value: "Canadian" },
    { key: "Cape Verdean", value: "Cape Verdean" },
    { key: "Central African", value: "Central African" },
    { key: "Chadian", value: "Chadian" },
    { key: "Chilean", value: "Chilean" },
    { key: "Chinese", value: "Chinese" },
    { key: "Colombian", value: "Colombian" },
    { key: "Comoran", value: "Comoran" },
    { key: "Congolese", value: "Congolese" },
    { key: "Costa Rican", value: "Costa Rican" },
    { key: "Croatian", value: "Croatian" },
    { key: "Cuban", value: "Cuban" },
    { key: "Cypriot", value: "Cypriot" },
    { key: "Czech", value: "Czech" },
    { key: "Danish", value: "Danish" },
    { key: "Djibouti", value: "Djibouti" },
    { key: "Dominican", value: "Dominican" },
    { key: "Dutch", value: "Dutch" },
    { key: "East Timorese", value: "East Timorese" },
    { key: "Ecuadorean", value: "Ecuadorean" },
    { key: "Egyptian", value: "Egyptian" },
    { key: "Emirian", value: "Emirian" },
    { key: "Equatorial Guinean", value: "Equatorial Guinean" },
    { key: "Eritrean", value: "Eritrean" },
    { key: "Estonian", value: "Estonian" },
    { key: "Ethiopian", value: "Ethiopian" },
    { key: "Fijian", value: "Fijian" },
    { key: "Filipino", value: "Filipino" },
    { key: "Finnish", value: "Finnish" },
    { key: "French", value: "French" },
    { key: "Gabonese", value: "Gabonese" },
    { key: "Gambian", value: "Gambian" },
    { key: "Georgian", value: "Georgian" },
    { key: "German", value: "German" },
    { key: "Ghanaian", value: "Ghanaian" },
    { key: "Greek", value: "Greek" },
    { key: "Grenadian", value: "Grenadian" },
    { key: "Guatemalan", value: "Guatemalan" },
    { key: "Guinea-Bissauan", value: "Guinea-Bissauan" },
    { key: "Guinean", value: "Guinean" },
    { key: "Guyanese", value: "Guyanese" },
    { key: "Haitian", value: "Haitian" },
    { key: "Herzegovinian", value: "Herzegovinian" },
    { key: "Honduran", value: "Honduran" },
    { key: "Hungarian", value: "Hungarian" },
    { key: "I-Kiribati", value: "I-Kiribati" },
    { key: "Icelander", value: "Icelander" },
    { key: "Indian", value: "Indian" },
    { key: "Indonesian", value: "Indonesian" },
    { key: "Iranian", value: "Iranian" },
    { key: "Iraqi", value: "Iraqi" },
    { key: "Irish", value: "Irish" },
    { key: "Israeli", value: "Israeli" },
    { key: "Italian", value: "Italian" },
    { key: "Ivorian", value: "Ivorian" },
    { key: "Jamaican", value: "Jamaican" },
    { key: "Japanese", value: "Japanese" },
    { key: "Jordanian", value: "Jordanian" },
    { key: "Kazakhstani", value: "Kazakhstani" },
    { key: "Kenyan", value: "Kenyan" },
    { key: "Kittian and Nevisian", value: "Kittian and Nevisian" },
    { key: "Kuwaiti", value: "Kuwaiti" },
    { key: "Kyrgyz", value: "Kyrgyz" },
    { key: "Laotian", value: "Laotian" },
    { key: "Latvian", value: "Latvian" },
    { key: "Lebanese", value: "Lebanese" },
    { key: "Liberian", value: "Liberian" },
    { key: "Libyan", value: "Libyan" },
    { key: "Liechtensteiner", value: "Liechtensteiner" },
    { key: "Lithuanian", value: "Lithuanian" },
    { key: "Luxembourger", value: "Luxembourger" },
    { key: "Macedonian", value: "Macedonian" },
    { key: "Malagasy", value: "Malagasy" },
    { key: "Malawian", value: "Malawian" },
    { key: "Malaysian", value: "Malaysian" },
    { key: "Maldivian", value: "Maldivian" },
    { key: "Malian", value: "Malian" },
    { key: "Maltese", value: "Maltese" },
    { key: "Marshallese", value: "Marshallese" },
    { key: "Mauritanian", value: "Mauritanian" },
    { key: "Mauritian", value: "Mauritian" },
    { key: "Mexican", value: "Mexican" },
    { key: "Micronesian", value: "Micronesian" },
    { key: "Moldovan", value: "Moldovan" },
    { key: "Monacan", value: "Monacan" },
    { key: "Mongolian", value: "Mongolian" },
    { key: "Moroccan", value: "Moroccan" },
    { key: "Mosotho", value: "Mosotho" },
    { key: "Motswana", value: "Motswana" },
    { key: "Mozambican", value: "Mozambican" },
    { key: "Namibian", value: "Namibian" },
    { key: "Nauruan", value: "Nauruan" },
    { key: "Nepalese", value: "Nepalese" },
    { key: "New Zealander", value: "New Zealander" },
    { key: "Ni-Vanuatu", value: "Ni-Vanuatu" },
    { key: "Nicaraguan", value: "Nicaraguan" },
    { key: "Nigerian", value: "Nigerian" },
    { key: "Nigerien", value: "Nigerien" },
    { key: "North Korean", value: "North Korean" },
    { key: "Northern Irish", value: "Northern Irish" },
    { key: "Norwegian", value: "Norwegian" },
    { key: "Omani", value: "Omani" },
    { key: "Pakistani", value: "Pakistani" },
    { key: "Palauan", value: "Palauan" },
    { key: "Panamanian", value: "Panamanian" },
    { key: "Papua New Guinean", value: "Papua New Guinean" },
    { key: "Paraguayan", value: "Paraguayan" },
    { key: "Peruvian", value: "Peruvian" },
    { key: "Polish", value: "Polish" },
    { key: "Portuguese", value: "Portuguese" },
    { key: "Qatari", value: "Qatari" },
    { key: "Romanian", value: "Romanian" },
    { key: "Russian", value: "Russian" },
    { key: "Rwandan", value: "Rwandan" },
    { key: "Saint Lucian", value: "Saint Lucian" },
    { key: "Salvadoran", value: "Salvadoran" },
    { key: "Samoan", value: "Samoan" },
    { key: "San Marinese", value: "San Marinese" },
    { key: "Sao Tomean", value: "Sao Tomean" },
    { key: "Saudi", value: "Saudi" },
    { key: "Scottish", value: "Scottish" },
    { key: "Senegalese", value: "Senegalese" },
    { key: "Serbian", value: "Serbian" },
    { key: "Seychellois", value: "Seychellois" },
    { key: "Sierra Leonean", value: "Sierra Leonean" },
    { key: "Singaporean", value: "Singaporean" },
    { key: "Slovakian", value: "Slovakian" },
    { key: "Slovenian", value: "Slovenian" },
    { key: "Solomon Islander", value: "Solomon Islander" },
    { key: "Somali", value: "Somali" },
    { key: "South African", value: "South African" },
    { key: "South Korean", value: "South Korean" },
    { key: "Spanish", value: "Spanish" },
    { key: "Sri Lankan", value: "Sri Lankan" },
    { key: "Sudanese", value: "Sudanese" },
    { key: "Surinamer", value: "Surinamer" },
    { key: "Swazi", value: "Swazi" },
    { key: "Swedish", value: "Swedish" },
    { key: "Swiss", value: "Swiss" },
    { key: "Syrian", value: "Syrian" },
    { key: "Taiwanese", value: "Taiwanese" },
    { key: "Tajik", value: "Tajik" },
    { key: "Tanzanian", value: "Tanzanian" },
    { key: "Thai", value: "Thai" },
    { key: "Togolese", value: "Togolese" },
    { key: "Tongan", value: "Tongan" },
    { key: "Trinidadian or Tobagonian", value: "Trinidadian or Tobagonian" },
    { key: "Tunisian", value: "Tunisian" },
    { key: "Turkish", value: "Turkish" },
    { key: "Tuvaluan", value: "Tuvaluan" },
    { key: "Ugandan", value: "Ugandan" },
    { key: "Ukrainian", value: "Ukrainian" },
    { key: "Uruguayan", value: "Uruguayan" },
    { key: "Uzbekistani", value: "Uzbekistani" },
    { key: "Venezuelan", value: "Venezuelan" },
    { key: "Vietnamese", value: "Vietnamese" },
    { key: "Welsh", value: "Welsh" },
    { key: "Yemenite", value: "Yemenite" },
    { key: "Zambian", value: "Zambian" },
    { key: "Zimbabwean", value: "Zimbabwean" },
  ];
}
export function Industries() {
  return [
    {
      key: "Accountancy, banking and finance",
      value: "Accountancy, banking and finance",
    },
    {
      key: "Business, consulting and management",
      value: "Business, consulting and management",
    },
    { key: "Charity and voluntary work", value: "Charity and voluntary work" },
    { key: "Creative arts and design", value: "Creative arts and design" },
    { key: "Energy and utilities", value: "Energy and utilities" },
    {
      key: "Engineering and manufacturing",
      value: "Engineering and manufacturing",
    },
    {
      key: "Environment and agriculture",
      value: "Environment and agriculture",
    },
    { key: "Healthcare", value: "Healthcare" },
    {
      key: "Hospitality and events management",
      value: "Hospitality and events management",
    },
    { key: "Information technology", value: "Information technology" },
    { key: "Law", value: "Law" },
    {
      key: "Law enforcement and security",
      value: "Law enforcement and security",
    },
    { key: "Leisure, sport and tourism", value: "Leisure, sport and tourism" },
    {
      key: "Marketing, advertising and PR",
      value: "Marketing, advertising and PR",
    },
    { key: "Media and internet", value: "Media and internet" },
    { key: "Property and construction", value: "Property and construction" },
    {
      key: "Public services and administration",
      value: "Public services and administration",
    },
    { key: "Recruitment and HR", value: "Recruitment and HR" },
    { key: "Retail", value: "Retail" },
    { key: "Sales", value: "Sales" },
    {
      key: "Science and pharmaceuticals",
      value: "Science and pharmaceuticals",
    },
    { key: "Social care", value: "Social care" },
    {
      key: "Teacher training and education",
      value: "Teacher training and education",
    },
    { key: "Transport and logistics", value: "Transport and logistics" },
  ];
}

import React, { useState, useEffect } from "react";
import Loader from "../../Helpers/Loader";
import { Post } from "../../../Utilities/CommonAPI";
import { toast } from "react-toastify";
import { ApiPaths } from "../../../Services/Constants";

export default function UserRoles() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [role, setRole] = useState("");
  const [newRole, setNewRole] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    fetch(ApiPaths.BaseUrl + "/api/roles")
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setIsLoading(false);
      });
  }, []);

  function onAddRole(e) {
    e.preventDefault();
    setIsLoading(true);
    Post(
      "/api/roles",
      role,
      (response) => {
        setIsLoading(false);
        if (response.isSuccess) {
          toast.success("Completed successfully");
          fetch(ApiPaths.BaseUrl + "/api/roles")
            .then((response) => response.json())
            .then((json) => {
              setData(json);
              setIsLoading(false);
            });
          ClearData();
        } else {
          toast.error(response.message);
        }
      },
      (err) => {
        setIsLoading(false);
        toast.error("Process was unsuccessful");
        console.log(err);
      },
      false
    );
  }

  function onDelete(userRole) {
    var role = userRole.name;
    setIsLoading(true);
    Post(
      "/api/roles/" + role,
      "",
      (response) => {
        setIsLoading(false);
        if (response.isSuccess) {
          toast.success("Completed successfully");
          fetch(ApiPaths.BaseUrl + "/api/roles")
            .then((response) => response.json())
            .then((json) => {
              setData(json);
              setIsLoading(false);
            });
        } else {
          toast.error(response.message);
        }
      },
      (err) => {
        setIsLoading(false);
        toast.error("Process was unsuccessful");
        console.log(err);
      },
      false
    );
  }

  function onEdit(role) {
    setRole(role.name);
    setIsUpdate(true);
  }

  function onUpdate() {
    setIsLoading(true);
    Post(
      "/api/roles/update?role=" + role + "&newRole=" + newRole + "",
      "",
      (response) => {
        setIsLoading(false);
        if (response.isSuccess) {
          toast.success("Completed successfully");
          fetch(ApiPaths.BaseUrl + "/api/roles")
            .then((response) => response.json())
            .then((json) => {
              setData(json);
              setIsLoading(false);
            });
          ClearData();
        } else {
          toast.error(response.message);
        }
      },
      (err) => {
        setIsLoading(false);
        toast.error("Process was unsuccessful");
        console.log(err);
      },
      false
    );
  }

  function ClearData() {
    setRole("");
    setNewRole("");
    setIsUpdate(false);
  }

  return (
    <div>
      <header className="border-bottom bg-white mb-4">
        <div className="container-fluid px-4">
          <div className="page-header-content">
            <div className="row align-items-center justify-content-between pt-3">
              <div className="col-auto mb-3">
                <h4 className="">User Roles</h4>
              </div>
              <div className="col-12 col-xl-auto mb-3">
                <a className="btn btn-sm btn-primary me-3" href="/search">
                  Manage Users
                </a>
                <button
                  className="btn btn-sm btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Create User Role
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid px-4">
        <div className="row">
          <div className="col-md-12 ">
            <div className="card mb-4">
              <div className="card-header">User Roles</div>
              <div className="card-body">
                {data.map((role, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-between mb-4"
                  >
                    <div className="d-flex align-items-center flex-shrink-0 me-3">
                      <div className="avatar avatar-xl me-3 bg-gray-200"></div>
                      <div className="d-flex flex-column fw-bold">
                        <div className="text-dark line-height-normal mb-1">
                          {role.name}
                        </div>
                      </div>
                    </div>
                    <div className="dropdown no-caret">
                      <button
                        className="btn btn-transparent-dark btn-icon dropdown-toggle"
                        id="dropdownPeople1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-more-vertical"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end animated--fade-in-up"
                        aria-labelledby="dropdownPeople1"
                      >
                        <button
                          type="button"
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => {
                            onEdit(role);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn-link dropdown-item"
                          onClick={() => {
                            onDelete(role);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {isUpdate ? "Update" : "Add"} User Role
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading ? (
              <Loader isLoading={isLoading} />
            ) : (
              <div>
                <div className="modal-body">
                  <div className="">
                    <label className="mb-3" htmlFor="roleName">
                      Role Name
                    </label>
                    {!isUpdate ? (
                      <input
                        id="roleName"
                        name="roleName"
                        type="text"
                        className="form-control"
                        required
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      />
                    ) : (
                      <input
                        id="roleName"
                        name="roleName"
                        type="text"
                        className="form-control"
                        required
                        value={newRole}
                        onChange={(e) => setNewRole(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {!isUpdate ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={onAddRole}
                    >
                      Add Role
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={onUpdate}
                    >
                      Update Role
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import profilePicture from "../../Assets/images/profile-1.png";
import Cookies from "universal-cookie";
import { clearLocalData } from "../../Services/DataService";

export class Navbar extends Component {
  logout = (e) => {
    const cookies = new Cookies();
    cookies.remove("_myifcm");
    clearLocalData();
  };
  render() {
    return (
      <nav className="sb-topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white">
        <a className="navbar-brand ps-3" href="/">
          MyIFCM
        </a>
        <button
          className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
          id="sidebarToggle"
          href="#!"
        >
          <i className="fas fa-bars"></i>
        </button>
        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
          <div className="input-group">
            <input
              className="form-control"
              type="search"
              placeholder="Search for..."
              aria-label="Search for..."
              aria-describedby="btnNavbarSearch"
            />
          </div>
        </form>
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              href="/"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={profilePicture}
                alt="mdo"
                width="32"
                height="32"
                className="rounded-circle"
              />
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <a className="dropdown-item" href="/profile">
                  {" "}
                  Profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/notifications">
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#!">
                  Activity Log
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/login"
                  onClick={this.logout}
                >
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;

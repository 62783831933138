import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <footer className="py-4 bg-light mt-auto">
        <div className="container-fluid px-4">
          <div className="d-flex align-items-center justify-content-between small">
            <div className="text-muted">
              Copyright &copy; Impact for Christ Ministries 2022
            </div>
            <div>
              <a
                href="https://www.impactforchristsa.com/en/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              &middot;
              <a
                href="https://www.impactforchristsa.com/en/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

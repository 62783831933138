import React, { Component } from "react";
import Loader from "../Helpers/Loader";
import { Post } from "../../Utilities/CommonAPI";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSignIn = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    Post(
      "/reset-password",
      {
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      },
      (data) => {
        if (data && data.isSuccess) {
          //   SetToken(data);
          //   setLocalData(AppData.Username, data.username);
          window.location.href = "/";
        } else {
          this.setState({ isLoading: false, errors: data.errors });
        }
      },
      (error) => {
        console.log(error);
        this.setState({ isLoading: false, errors: error.errors });
      }
    );
  };

  render() {
    const { password, confirmPassword, isLoading, errors } = this.state;
    return (
      <section className="pt-xxl-5">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">MyIFCM</h1>
                          <p>Impact for Christ Ministries</p>
                        </div>
                        {errors ? (
                          <ul className="text-start text-danger">
                            {errors.map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                        {isLoading ? (
                          <Loader isLoading={isLoading} />
                        ) : (
                          <form className="" onSubmit={this.onSignIn}>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                required
                                type="text"
                                className="form-control"
                                id="password"
                                name="password"
                                value={password}
                              />
                              <label htmlFor="email">Password</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                onChange={this.changeHandler}
                                required
                                type="text"
                                className="form-control"
                                name="confirmPassword"
                                value={confirmPassword}
                              />
                              <label htmlFor="confirmPassword">
                                Confirm Password
                              </label>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                href="/"
                              >
                                Reset Password
                              </button>
                            </div>
                            <hr />
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ResetPassword;

import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Layout extends Component {
  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div id="layoutSidenav">
          <Sidebar></Sidebar>
          <div id="layoutSidenav_content">
            <main className="main-body">
              <Outlet />
            </main>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover={false}
            />
            <Footer></Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;

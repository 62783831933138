import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import professionalImg from "../../Assets/images/in-house-finance-professional.svg";
import visualCollaborationImg from "../../Assets/images/Visual collaboration.svg";
import bookingsImg from "../../Assets/images/Calendar.svg";
import reportsImg from "../../Assets/images/Git request.svg";
import Datatable from "../Reports/Datatable";
import Loader from "../Helpers/Loader";
import { ApiPaths } from "../../Services/Constants";

export function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState([
    "firstName",
    "lastName",
    "cellphone",
  ]);

  useEffect(() => {
    fetch(ApiPaths.BaseUrl + "/Members")
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setIsLoading(false);
      });
  }, []);

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = data[0] && Object.keys(data[0]);

  return (
    <div className="container-fluid px-4">
      {/* <h1 className="mt-4">Dashboard</h1> */}
      <div className="row mt-4">
        <div className="col-12">
          <div className="card card-waves mb-4">
            <div className="card-body px-5">
              <div className="row align-items-center justify-content-between">
                <div className="col">
                  <h2 className="text-primary">Welcome back</h2>
                  <p className="text-gray-700">
                    Great job, your MyIFCM dashboard is ready to go! You can
                    update your personal details, generate reports, book for
                    events, apply to join departments.
                  </p>
                  <Link className="btn btn-primary" to="/profile">
                    Get Started
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-right ms-1"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </Link>
                </div>
                <div className="col d-none d-lg-block mt-xxl-n4">
                  <img
                    alt=""
                    className="img-fluid px-xl-4 mt-xxl-n5 mh-icon-25 float-end"
                    src={professionalImg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="card mb-4">
            <div className="card-body py-5">
              <div className="d-flex flex-column justify-content-center">
                <img
                  src={reportsImg}
                  alt=""
                  className="img-fluid mb-4 mh-icon-20"
                />
                <div className="text-center px-0 px-lg-5">
                  <h5>Build Reports</h5>
                  <p className="mb-4">You can build your custom reports.</p>
                  <Link to="/search" className="btn btn-primary">
                    Build Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="card mb-4">
            <div className="card-body py-5">
              <div className="d-flex flex-column justify-content-center">
                <img
                  src={visualCollaborationImg}
                  alt=""
                  className="img-fluid mb-4 mh-icon-20"
                />
                <div className="text-center px-0 px-lg-5">
                  <h5>Start Serving</h5>
                  <p className="mb-4">Be a participator, join a department.</p>
                  <Link to="/serving" className="btn btn-primary">
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="card mb-4">
            <div className="card-body py-5">
              <div className="d-flex flex-column justify-content-center">
                <img
                  src={bookingsImg}
                  alt=""
                  className="img-fluid mb-4 mh-icon-20"
                />
                <div className="text-center px-0 px-lg-5">
                  <h5>Bookings</h5>
                  <p className="mb-4">Bookings for Shiloh, Via Dolorosa etc.</p>
                  <Link to="/bookings" className="btn btn-primary">
                    View Bookings
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="card mb-4">
            <div className="card-header">
              <i className="fas fa-chart-area me-1"></i>
              Evangelism
            </div>
            <div className="card-body">
              <canvas id="myAreaChart" width="100%" height="40"></canvas>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card mb-4">
            <div className="card-header">
              <i className="fas fa-chart-bar me-1"></i>
              Church attendance
            </div>
            <div className="card-body">
              <canvas id="myBarChart" width="100%" height="40"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-users me-1"></i>
          IFCM Members
        </div>
        <div className="card-body">
          <div className="row">
            <div className=" d-md-inline-block">
              <div className="input-group">
                <div className="col-12 col-md-3">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search for..."
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                </div>
                <div className="col-12 col-md-9">
                  {columns &&
                    columns.map((column, index) => (
                      <label className="m-2 text-capitalize" key={index}>
                        <input
                          className="m-1 form-check-input"
                          type="checkbox"
                          checked={searchColumns.includes(column)}
                          onChange={(e) => {
                            const checked = searchColumns.includes(column);
                            setSearchColumns((prev) =>
                              checked
                                ? prev.filter((sc) => sc !== column)
                                : [...prev, column]
                            );
                          }}
                        />
                        {column}
                      </label>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col mt-3">
              {isLoading ? (
                <Loader isLoading={isLoading} />
              ) : (
                <Datatable data={search(data)} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import React, { Component } from "react";

export class ForgotPassword extends Component {
  render() {
    return (
      <section className="pt-xxl-5">
        <div className="container my-5">
          {/* <!-- Outer Row --> */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  {/* <!-- Nested Row within Card Body --> */}
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">MyIFCM</h1>
                          <p>Impact for Christ Ministries</p>
                        </div>
                        <form className="">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email Address..."
                            />
                            <label htmlFor="email">Email Address</label>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <a className="small" href="/login">
                              Return to Login
                            </a>
                            <a
                              className="btn btn-primary float-right"
                              href="/login"
                            >
                              Send
                            </a>
                          </div>
                        </form>
                        <hr />
                        <div className="text-center">
                          <a className="small" href="/register">
                            Create an Account!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import Datatable from "./Datatable";
import Loader from "../Helpers/Loader";
import { ApiPaths } from "../../Services/Constants";

export default function Search() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState([
    "firstName",
    "lastName",
    "cellphone",
  ]);

  useEffect(() => {
    fetch(ApiPaths.BaseUrl + "/Members")
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setIsLoading(false);
      });
  }, []);

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = data[0] && Object.keys(data[0]);

  return (
    <div className="container-fluid px-4">
      <div className="row mt-4">
        <div className="col">
          <div className="card mb-4">
            <div className="card-header">
              <i className="fas fa-users me-1"></i>
              IFCM Members
            </div>
            <div className="card-body">
              <div className="row">
                <div className=" d-md-inline-block">
                  <div className="input-group">
                    <div className="col-12 col-md-3">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Search for..."
                        value={q}
                        onChange={(e) => setQ(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-9">
                      {columns &&
                        columns.map((column, index) => (
                          <label className="m-2 text-capitalize" key={index}>
                            <input
                              className="m-1 form-check-input"
                              type="checkbox"
                              checked={searchColumns.includes(column)}
                              onChange={(e) => {
                                const checked = searchColumns.includes(column);
                                setSearchColumns((prev) =>
                                  checked
                                    ? prev.filter((sc) => sc !== column)
                                    : [...prev, column]
                                );
                              }}
                            />
                            {column}
                          </label>
                        ))}
                      {/* <button
                        type="button"
                        className="btn btn-primary position-absolute end-0"
                      >
                        Export CSV
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col mt-3">
                  {isLoading ? (
                    <Loader isLoading={isLoading} />
                  ) : (
                    <Datatable data={search(data)} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

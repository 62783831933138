import { Get } from "../Utilities/CommonAPI";

export function setLocalData(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}
export function getLocalData(key) {
  var data = localStorage.getItem(key);
  if (data) return JSON.parse(data);
  return;
}
export function removeLocalData(key) {
  localStorage.removeItem(key);
}
export function clearLocalData() {
  localStorage.clear();
}
export function getData(url, key, successCallback, errorCallback) {
  var data = getLocalData(key);
  if (data !== undefined) {
    return successCallback(data);
  }
  Get(
    url,
    (response) => {
      successCallback(response);
      setLocalData(key, response);
    },
    (err) => {
      errorCallback(err);
    }
  );
}

import React, { Component } from "react";
import { Post } from "../../Utilities/CommonAPI";
import { getData, removeLocalData } from "../../Services/DataService";
import { ApiPaths, AppData } from "../../Services/Constants";
import { Industries } from "../../Utilities/DataLists";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";

export class ProfessionAndQualifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highestQualification: "",
      occupation: "",
      employerName: "",
      skills: [],
      skill: "",
      industry: "",
      vocation: "",
      industries: Industries(),
      isVisible: this.props.isVisible,
      isLoading: false,
    };
  }
  componentDidMount() {
    getData(
      ApiPaths.PersonalDetails,
      AppData.PeronsalDetails,
      (data) => {
        this.setModel(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setModel = (model) => {
    this.setState({
      highestQualification: model.highestQualification
        ? model.highestQualification
        : "",
      occupation: model.occupation ? model.occupation : "",
      employerName: model.employerName ? model.employerName : "",
      skills: model.skills ? model.skills : [],
      industry: model.industry ? model.industry : "",
      vocation: model.vocation ? model.vocation : "",
    });
  };
  openSection = (e) => {
    this.props.openSection(e);
  };
  closeSection = (e) => {
    this.props.closeSection(e);
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  addSkill = (e) => {
    e.preventDefault();
    this.state.skills.push({ name: this.state.skill });
    this.setState({ skill: "", skills: this.state.skills });
  };
  removeSkill = (e) => {
    var skills = this.state.skills.filter((x) => x.name !== e.name);
    this.setState({ skills: skills });
  };
  onUpdate = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var body = {
      highestQualification: this.state.highestQualification,
      occupation: this.state.occupation,
      employerName: this.state.employerName,
      // skills: this.state.skills,
      industry: this.state.industry,
      vocation: this.state.vocation,
    };
    Post(
      "/PersonalDetails/update/qualifications",
      body,
      (response) => {
        this.setState({ isLoading: false });
        this.closeSection(e);
        removeLocalData(AppData.PeronsalDetails);
        toast.success("Updated successfully");
      },
      (err) => {
        this.setState({ isLoading: false });
        toast.error("Update unsuccessful!");
        console.log(err);
      },
      true
    );
  };
  render() {
    const isVisible = this.props.isVisible;
    const {
      highestQualification,
      occupation,
      employerName,
      industry,
      vocation,
      industries,
      isLoading,
    } = this.state;
    return (
      <div className="card shadow-lg mb-4">
        <div className="card-header py-2">
          <div className="row">
            <div className="col-sm-8">Profession And Qualifications</div>
            <div className="col-sm-4">
              {!isVisible ? (
                <button
                  onClick={this.openSection}
                  type="button"
                  className="btn btn-primary btn-sm float-end"
                  data-section="profession-and-qualifications-details"
                >
                  Edit
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {isVisible ? (
          <div className="card-body">
            {isLoading ? (
              <Loader isLoading={isLoading} />
            ) : (
              <form onSubmit={this.onUpdate}>
                <div className="row gx-3">
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="highestQualification"
                        name="highestQualification"
                        type="text"
                        placeholder="Highest Qualification"
                        value={highestQualification}
                      />
                      <label
                        className="small mb-1"
                        htmlFor="highestQualification"
                      >
                        Highest Qualification
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="vocation"
                        name="vocation"
                        type="text"
                        value={vocation}
                      >
                        <option value="">-- Select --</option>
                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                        <option value="Self-Employed">Self-Employed</option>
                        <option value="Employed">Employed</option>
                      </select>
                      <label className="small mb-1" htmlFor="vocation">
                        Vocation
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="occupation"
                        name="occupation"
                        type="text"
                        placeholder="Occupation"
                        value={occupation}
                      />
                      <label className="small mb-1" htmlFor="occupation">
                        Occupation e.g. Teacher, Banker, Waiter
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="employerName"
                        name="employerName"
                        type="text"
                        placeholder="Company Name"
                        value={employerName}
                      />
                      <label className="small mb-1" htmlFor="employerName">
                        Company Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="industry"
                        name="industry"
                        type="industry"
                        value={industry}
                      >
                        <option value="">-- Select --</option>
                        {industries.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </select>
                      <label className="small mb-1" htmlFor="industry">
                        Which industry do you work in?
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="row gx-3 mb-3">
              <div className="col-md-8 mb-1">
                <div className="form-floating">
                  <input
                    onChange={this.changeHandler}
                    className="form-control form-floating"
                    id="skill"
                    name="skill"
                    type="skill"
                    value={skill}
                  />
                  <label className="small mb-1" htmlFor="skill">
                    What are your best Skills?
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <button
                  type="button"
                  onClick={this.addSkill}
                  className="btn btn-primary btn-sm"
                >
                  Add
                </button>
              </div>
            </div> */}
                {/* {skills.map((item, index) => (
              <div className="row gx-3 mb-3" key={index}>
                <div className="col-md-8 mb-1">
                  <div className="form-floating">
                    <input
                      onChange={this.changeHandler}
                      className="form-control form-floating"
                      value={item.name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    onClick={() => this.removeSkill(item)}
                    className="btn btn-primary btn-sm"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))} */}
                <div className="col-12 text-center">
                  <button
                    className="btn btn-secondary mx-2"
                    onClick={this.closeSection}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary mx-2">
                    Update
                  </button>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div className="card-body">
            <div className="row gx-3">
              <div className="col-md-6">
                <label className="small mb-3">
                  Highest Qualification: <strong>{highestQualification}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Vocation: <strong>{vocation}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Occupation: <strong>{occupation}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Company Name: <strong>{employerName}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Industry: <strong>{industry}</strong>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ProfessionAndQualifications;

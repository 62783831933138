import React, { Component } from "react";
import { Titles, Relationships } from "../../Utilities/DataLists";
import { Post } from "../../Utilities/CommonAPI";
import { getData, removeLocalData } from "../../Services/DataService";
import { ApiPaths, AppData } from "../../Services/Constants";
import Loader from "../Helpers/Loader";
import { toast } from "react-toastify";

export class NextOfKin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      firstName: "",
      lastName: "",
      cellphone: "",
      email: "",
      relationship: "",
      relationships: Relationships(),
      titles: Titles(),
      isVisible: this.props.isVisible,
      isLoading: false,
    };
  }
  componentDidMount() {
    getData(
      ApiPaths.PersonalDetails,
      AppData.PeronsalDetails,
      (data) => {
        if (data.nextOfKin) {
          this.setModel(data.nextOfKin);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setModel = (model) => {
    this.setState({
      title: model.title ? model.title : "",
      firstName: model.firstName ? model.firstName : "",
      lastName: model.lastName ? model.lastName : "",
      cellphone: model.cellphoneNumber ? model.cellphoneNumber : "",
      email: model.email ? model.email : "",
      relationship: model.relationship ? model.relationship : "",
    });
  };
  openSection = (e) => {
    this.props.openSection(e);
  };
  closeSection = (e) => {
    this.props.closeSection(e);
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onUpdate = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var body = {
      title: this.state.title,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      CellphoneNumber: this.state.cellphone,
      email: this.state.email,
      relationship: this.state.relationship,
    };
    Post(
      "/PersonalDetails/update/nextofkin",
      body,
      (response) => {
        this.setState({ isLoading: false });
        this.closeSection(e);
        removeLocalData(AppData.PeronsalDetails);
        toast.success("Updated successfully");
      },
      (err) => {
        this.setState({ isLoading: false });
        toast.error("Update unsuccessful!");
        console.log(err);
      },
      true
    );
  };
  render() {
    const isVisible = this.props.isVisible;
    const {
      title,
      firstName,
      lastName,
      cellphone,
      email,
      relationship,
      relationships,
      titles,
      isLoading,
    } = this.state;
    return (
      <div className="card shadow-lg mb-4">
        <div className="card-header py-2">
          <div className="row">
            <div className="col-sm-8">Next of Kin Details</div>
            <div className="col-sm-4">
              {!isVisible ? (
                <button
                  onClick={this.openSection}
                  type="button"
                  className="btn btn-primary btn-sm float-end"
                  data-section="next-of-kin-details"
                >
                  Edit
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {isVisible ? (
          <div className="card-body">
            {isLoading ? (
              <Loader isLoading={isLoading} />
            ) : (
              <form onSubmit={this.onUpdate}>
                <div className="row gx-3">
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <select
                        className="form-control form-floating"
                        name="title"
                        onChange={this.changeHandler}
                        value={title}
                      >
                        <option value="">-- Select --</option>
                        {titles.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </select>
                      <label className="small mb-1" htmlFor="title">
                        Title
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        required
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                      />
                      <label className="small mb-1" htmlFor="firstName">
                        First name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        required
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={lastName}
                      />
                      <label className="small mb-1" htmlFor="lastName">
                        Last name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        required
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="cellphone"
                        name="cellphone"
                        type="number"
                        placeholder="Cellphone Number"
                        value={cellphone}
                      />
                      <label className="small mb-1" htmlFor="cellphone">
                        Cellphone Number
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <input
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        value={email}
                      />
                      <label className="small mb-1" htmlFor="email">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-floating">
                      <select
                        onChange={this.changeHandler}
                        className="form-control form-floating"
                        id="relationship"
                        name="relationship"
                        value={relationship}
                      >
                        <option value="">-- Select --</option>
                        {relationships.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.key}
                          </option>
                        ))}
                      </select>
                      <label className="small mb-1" htmlFor="relationship">
                        Relationship
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-secondary mx-2"
                    onClick={this.closeSection}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary mx-2">
                    Update
                  </button>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div className="card-body">
            <div className="row gx-3">
              <div className="col-md-6">
                <label className="small mb-3">
                  Title: <strong>{title}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  First name: <strong>{firstName}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Last name: <strong>{lastName}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-1">
                  Cellphone: <strong>{cellphone}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Email: <strong>{email}</strong>
                </label>
              </div>
              <div className="col-md-6">
                <label className="small mb-3">
                  Relationship: <strong>{relationship}</strong>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NextOfKin;

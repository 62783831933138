import axios from "axios";
import { GetToken } from "../Components/Authentication/AuthorizeService";
import Cookies from "universal-cookie";
import { clearLocalData } from "../Services/DataService";
import { ApiPaths } from "../Services/Constants";

export function Post(
  action,
  body,
  successCallback,
  errorCallback,
  hasHeaders = false
) {
  if (hasHeaders) {
    var cookie = GetToken();
    if (!cookie) {
      return;
    }
    axios
      .post(ApiPaths.BaseUrl + action, body, {
        headers: {
          "Content-Type": "application/json",
          charset: "utf-8",
          Authorization: "Bearer " + cookie.token,
        },
      })
      .then((response) => {
        successCallback(response.data);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === "401"
        ) {
          const cookies = new Cookies();
          cookies.remove("_myifcm");
          clearLocalData();
        }
        errorCallback(error);
      });
  } else {
    axios
      .post(ApiPaths.BaseUrl + action, body, {
        headers: { "Content-Type": "application/json", charset: "utf-8" },
      })
      .then((response) => {
        successCallback(response.data);
      })
      .catch((error) => {
        errorCallback(error);
      });
  }
}
export function Get(action, successCallback, errorCallback) {
  var cookie = GetToken();
  if (!cookie) {
    return;
  }
  axios
    .get(ApiPaths.BaseUrl + action, {
      headers: { Authorization: "Bearer " + cookie.token },
    })
    .then((response) => successCallback(response.data))
    .catch((error) => {
      if (error && error.response && error.response.status) {
        if (error.response.status === 401 || error.response.status === "401") {
          const cookies = new Cookies();
          cookies.remove("_myifcm");
          clearLocalData();
        }
      }
      errorCallback(error);
    });
}
export function Delete(action, body, successCallback, errorCallback) {
  var cookie = GetToken();
  if (!cookie) {
    return;
  }
  axios
    .delete(ApiPaths.BaseUrl + action, body, {
      headers: { Authorization: "Bearer " + cookie.token },
    })
    .then((response) => successCallback(response.data))
    .catch((error) => {
      if (error && error.response && error.response.status) {
        if (error.response.status === 401 || error.response.status === "401") {
          const cookies = new Cookies();
          cookies.remove("_myifcm");
          clearLocalData();
        }
      }
      errorCallback(error);
    });
}
export function Put(action, body, successCallback, errorCallback) {
  var cookie = GetToken();
  if (!cookie) {
    return;
  }
  axios
    .put(ApiPaths.BaseUrl + action, body, {
      headers: {
        "Content-Type": "application/json",
        charset: "utf-8",
        Authorization: "Bearer " + cookie.token,
      },
    })
    .then((response) => successCallback(response.data))
    .catch((error) => {
      if (error && error.response && error.response.status) {
        if (error.response.status === 401 || error.response.status === "401") {
          const cookies = new Cookies();
          cookies.remove("_myifcm");
          clearLocalData();
        }
      }
      errorCallback(error);
    });
}

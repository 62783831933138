import React, { Component } from "react";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";

export class CreateReport extends Component {
  constructor(props) {
    super(props);

    this.state = { editorState: EditorState.createEmpty() };
    this.onChange = (editorState) => this.setState({ editorState });
  }

  render() {
    const styles = {
      root: {
        fontFamily: "'Helvetica', sans-serif",
        padding: 20,
        width: 600,
      },
      editor: {
        border: "1px solid #ccc",
        cursor: "text",
        minHeight: 80,
        padding: 10,
      },
      button: {
        marginTop: 10,
        textAlign: "center",
      },
    };
    return (
      <div className="container-fluid px-4">
        <h1 className="mt-4">Administration</h1>
        <ol className="breadcrumb mb-4">
          <li className="breadcrumb-item active">Reports</li>
          <li className="breadcrumb-item active">Create Report</li>
        </ol>
        <div className="container-fluid px-4 mb-4">
          <div className="row gx-4">
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-header">Post Title</div>
                <div className="card-body">
                  <input
                    className="form-control"
                    id="postTitleInput"
                    type="text"
                    placeholder="Enter your post title..."
                  />
                </div>
              </div>
              <div className="card card-header-actions mb-4">
                <div className="card-header">Post Preview</div>
                <div className="card-body">
                  <textarea
                    className="lh-base form-control"
                    type="text"
                    placeholder="Enter your post preview text..."
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div className="card card-header-actions mb-4 mb-lg-0">
                <div className="card-header">Post Content</div>
                <div className="card-body">
                  <Editor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-header-actions">
                <div className="card-header">Publish</div>
                <div className="card-body">
                  <div className="d-grid mb-3">
                    <button className="fw-500 btn btn-outline-primary">
                      Save as Draft
                    </button>
                  </div>
                  <div className="d-grid">
                    <button className="fw-500 btn btn-success">
                      Submit for Approval
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateReport;

import "./App.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import React, { useEffect, useState } from "react";
import Dashboard from "./Components/Dashboard/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Authentication/Login";
import Register from "./Components/Authentication/Register";
import Layout from "./Components/Layout/Layout";
import NotFound from "./Components/Error/NotFound";
import ForgotPassword from "./Components/Authentication/ForgotPassword";
import Profile from "./Components/Account/Profile";
import Security from "./Components/Account/Security";
import { IsLoggedIn } from "./Components/Authentication/AuthorizeService";
import ResetPassword from "./Components/Authentication/ResetPassword";
import Search from "./Components/Reports/Search";
import Bookings from "./Components/Bookings/Bookings";
import Serving from "./Components/Serving/Serving";
import Feedback from "./Components/Reports/Feedback";
import Notifications from "./Components/Account/Notifications";
import CreateReport from "./Components/Reports/CreateReport";
import UserRoles from "./Components/Authentication/RoleManagement/UserRoles";
import Groups from "./Components/UserManagement/Groups";
import Members from "./Components/UserManagement/Members";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    var loggedIn = IsLoggedIn();
    if (loggedIn) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={isLoggedIn ? <Layout /> : <Login />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/security" element={<Security />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/search" element={<Search />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/serving" element={<Serving />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/create-report" element={<CreateReport />} />
          <Route path="/user-roles" element={<UserRoles />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/members" element={<Members />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
